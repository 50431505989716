<template>
  <div class="animated fadeIn">
    <b-card header-tag="header" footer-tag="footer">
      <div slot="header">
        <strong>Performance</strong>
        <b-button variant="primary" class="my-2 my-sm-0 text-white" style="float: right;border-radius: 25px;" @click="fecharPerformance()">
              <i class="fa fa-close"></i>
        </b-button>
      </div>
      <b-card
        header-tag="header"
        footer-tag="footer"
        class="col-md-12 p-0 mr-2">
        <div slot="header">
          <strong> Filtros </strong>
        </div>
        <div class="p-1 mt-2">
          <b-row class="mb-1">
            <b-col sm="12" md="4" class="d-md-block">
              <b-button-toolbar class="float-left">
                <b-form-radio-group class="mr-3" buttons button-variant="outline-primary" v-model="filtro.fields.periodo" @change="consultaPeriodo" name="radiosBtn">
                  <b-form-radio class="mx-0" value="Diaria">Diária</b-form-radio>
                  <b-form-radio class="mx-0" value="Semanal">Semanal</b-form-radio>
                  <b-form-radio class="mx-0" value="Mensal">Mensal</b-form-radio>
                </b-form-radio-group>
              </b-button-toolbar>
            </b-col>
            <b-col sm="12" md="8">
              <div class="custom-control custom-checkbox pt-1">
                <input type="checkbox" class="custom-control-input" id="cbConsiderarOutrasLojas" v-model="filtro.fields.considerarOutrasLojas">
                <label class="custom-control-label" for="cbConsiderarOutrasLojas">Considerar vendas de outras lojas</label>
              </div>
            </b-col>

          </b-row>
          <b-row class="mb-1">
            <b-col sm="4" v-if="filtro.fields.periodo === 'Diaria'">
              <b-form-input :plain="true" v-model="filtro.fields.dataDia" type="date"></b-form-input>
            </b-col>
            <b-col sm="3" v-if="filtro.fields.periodo !== 'Diaria'" class="pr-md-0 mb-1 mb-md-0">
              <b-form-select :plain="true" v-model="filtro.fields.mes" :options="filtro.listas.meses" @change="setIntervalo"></b-form-select>
            </b-col>
            <b-col sm="2" v-if="filtro.fields.periodo !== 'Diaria'">
              <b-form-select  style="width: 78px" :plain="true" v-model="filtro.fields.ano" :options="filtro.listas.anos" @change="setIntervalo"></b-form-select>
            </b-col>
            <b-col :sm="['Semanal','Mensal'].includes(filtro.fields.periodo) ? 5 : 6" class="pr-md-0">
              <b-form-select style="width: 232px" :plain="true" v-model="filtro.fields.intervalo"  :options="filtro.listas.intervalo" :disabled="filtro.fields.periodo !== 'Semanal'" @change="setDatasIntervalo"></b-form-select>
            </b-col>
            <b-col sm="1">
              <b-button variant="primary" class="my-2 my-sm-0 text-white" :class="{'btn-block': $mq === 'sm'}" @click="search()">
                <i class="fa fa-search"></i>
              </b-button>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-card>

    <b-row>
      <b-col xs="12" sm="12" lg="4">
        <b-card :no-body="true">
          <b-card-body class="clearfix">
            <b-row class="mt-4">
              <b-col cols="12" sm="6" lg="6" class="avatar-center">
                <div class="d-flex" style="height: 134px;">
                  <img
                    :src="getPhoto(foto_vendedor)"
                    class="img-responsive img-circle img-avatar avatar-center"
                    style="height: 125px;width: 125px;"
                    @error="getAvatar"
                  />
                </div>
                <div class="d-flex">
                  <span class="font-weight-bold avatar-center">{{nomeFunc}}</span>
                </div>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col xs="12" sm="12" lg="8">
        <b-card :no-body="true">
          <b-card-body class="clearfix">
            <div class="text-left mb-0 h5">Metas</div>
            <b-row class="mt-4">
              <b-col cols="12" sm="6" lg="6">
                <div class="d-flex">
                  <gauge
                    :chartdata="charts.MetaAtingida"
                    class="w-100"
                    :height="134"
                    chartId="chart-gauge-01"
                    v-if="loaded"
                  />
                </div>
              </b-col>

              <b-col cols="12" sm="6" lg="6" class="pt-4">
                 <div class="d-flex">
                  <span class="text-muted">
                    <span
                      style="vertical-align: middle; margin-bottom: 2px;font-weight: bold;"
                    >&nbsp;&nbsp;&nbsp;Venda Realizada</span> 
                  </span>
                  <span class="ml-auto"  style="font-weight: bold;">{{ items.totais.vendas | currency }}</span>
                </div>
                <div class="d-flex">
                  <span class="text-muted">
                    <span
                      class="badge badge-meta-bronze"
                      style="vertical-align: middle; margin-bottom: 2px;"
                    >&nbsp;&nbsp;</span> Meta Bronze
                  </span>
                  <span class="ml-auto">{{ valorMetas.bronze | currency }}</span>
                </div>
                <div class="d-flex">
                  <span class="text-muted">
                    <span
                      class="badge badge-meta-prata"
                      style="vertical-align: middle; margin-bottom: 2px;"
                    >&nbsp;&nbsp;</span> Meta Prata
                  </span>
                  <span class="ml-auto">{{ valorMetas.prata | currency }}</span>
                </div>
                <div class="d-flex">
                  <span class="text-muted">
                    <span
                      class="badge badge-meta-ouro"
                      style="vertical-align: middle; margin-bottom: 2px;"
                    >&nbsp;&nbsp;</span> Meta Ouro
                  </span>
                  <span class="ml-auto">{{ valorMetas.ouro | currency }}</span>
                </div>
                <div class="d-flex">
                  <span class="text-muted">
                    <span
                      class="badge badge-meta-diamante"
                      style="vertical-align: middle; margin-bottom: 2px;"
                    >&nbsp;&nbsp;</span> Meta Diamante
                  </span>
                  <span class="ml-auto">{{ valorMetas.diamante | currency }}</span>
                </div>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" sm="6" lg="4">
        <b-card :no-body="true">
          <b-card-body class="clearfix">
            <i class="icon-up pecas p-2 font-2xl mr-2 float-left float-up"></i>
            <div class="text-right mb-1 h5">Tempo médio de espera</div>
            <div class="text-center mb-1 h5" :class="[
                  items.totais.tempo_medio_arrow === 'fa-minus' ? '' : (items.totais.tempo_medio_arrow === 'fa-arrow-up' ? 'text-success' : 'text-danger')
                ]">{{ items.totais.tempo_medio | number }}
                <i class="fa" :class="[
                  items.totais.tempo_medio_arrow,
                  items.totais.tempo_medio_arrow === 'fa-minus' ? 'text-warning' : (items.totais.tempo_medio_arrow === 'fa-arrow-up' ? 'text-success' : 'text-danger')
                ]" />
            </div>
            <div class="d-flex">
              <span class="ml-auto">Média Loja: {{ (items.totaisComparacao.tempo_medio) | number }}</span>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col cols="12" sm="6" lg="4">
        <b-card :no-body="true">
          <b-card-body class="clearfix">
            <i class="icon-up pecas p-3 font-2xl mr-3 float-left float-up"></i>
            <div class="text-right mb-1 h5">Peças</div>
            <div class="text-center mb-1 h5" :class="[
                  items.totais.pecas_arrow === 'fa-minus' ? '' : (items.totais.pecas_arrow === 'fa-arrow-up' ? 'text-success' : 'text-danger')
                ]">{{ items.totais.pecas | number }}
                <i class="fa" :class="[
                  items.totais.pecas_arrow,
                  items.totais.pecas_arrow === 'fa-minus' ? 'text-warning' : (items.totais.pecas_arrow === 'fa-arrow-up' ? 'text-success' : 'text-danger')
                ]" />
            </div>
            <div class="d-flex">
              <span class="ml-auto">Média Loja: {{ (items.totaisComparacao.pecas) | number }}</span>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col cols="12" sm="6" lg="4">
        <b-card :no-body="true">
          <b-card-body class="clearfix">
            <i class="icon-up atendimentos p-3 font-2xl mr-3 float-left float-up"></i>
            <div class="text-right mb-1 h5">Atendimentos</div>
            <div class="text-center mb-1 h5" :class="[
                  items.totais.atendimentos_arrow === 'fa-minus' ? '' : (items.totais.atendimentos_arrow === 'fa-arrow-up' ? 'text-success' : 'text-danger')
                ]">{{ items.totais.atendimentos | number }}
                <i class="fa" :class="[
                  items.totais.atendimentos_arrow,
                  items.totais.atendimentos_arrow === 'fa-minus' ? 'text-warning' : (items.totais.atendimentos_arrow === 'fa-arrow-up' ? 'text-success' : 'text-danger')
                ]" />
            </div>
            <div class="d-flex">
              <span class="ml-auto">Média Loja: {{ (items.totaisComparacao.atendimentos) | number }}</span>
            </div>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col cols="12" sm="6" lg="4">
        <b-card :no-body="true">
          <b-card-body class="clearfix">
            <i class="icon-up vendas-pecas p-3 font-2xl mr-3 float-left float-up"></i>
            <div class="text-right mb-1 h5">Vendas/Peças</div>
            <div class="text-center mb-1 h5" :class="[
                  items.totais.vp_arrow === 'fa-minus' ? '' : (items.totais.vp_arrow === 'fa-arrow-up' ? 'text-success' : 'text-danger')
                ]">{{ items.totais.vp | currency }}
                <i class="fa" :class="[
                  items.totais.vp_arrow,
                  items.totais.vp_arrow === 'fa-minus' ? 'text-warning' : (items.totais.vp_arrow === 'fa-arrow-up' ? 'text-success' : 'text-danger')
                ]" />
            </div>
            <div class="d-flex">
              <span class="ml-auto">Média Loja: {{ (items.totaisComparacao.vp) | currency }}</span>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col cols="12" sm="6" lg="4">
        <b-card :no-body="true">
          <b-card-body class="clearfix">
            <i class="icon-up vendas-atendimentos p-3 font-2xl mr-3 float-left float-up"></i>
            <div class="text-right mb-1 h5">Vendas/Atendimentos</div>
            <div class="text-center mb-1 h5" :class="[
                  items.totais.va_arrow === 'fa-minus' ? '' : (items.totais.va_arrow === 'fa-arrow-up' ? 'text-success' : 'text-danger')
                ]">{{ items.totais.va | currency }}
                <i class="fa" :class="[
                  items.totais.va_arrow,
                  items.totais.va_arrow === 'fa-minus' ? 'text-warning' : (items.totais.va_arrow === 'fa-arrow-up' ? 'text-success' : 'text-danger')
                ]" />
            </div>
            <div class="d-flex">
              <span class="ml-auto">Média Loja: {{ (items.totaisComparacao.va) | currency }}</span>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col cols="12" sm="6" lg="4">
        <b-card :no-body="true">
          <b-card-body class="clearfix">
            <i class="icon-up pecas-atendimentos p-3 font-2xl mr-3 float-left float-up"></i>
            <div class="text-right mb-1 h5">Peças/Atendimentos</div>
            <div class="text-center mb-1 h5" :class="[
                  items.totais.pa_arrow === 'fa-minus' ? '' : (items.totais.pa_arrow === 'fa-arrow-up' ? 'text-success' : 'text-danger')
                ]">{{Number(items.totais.pa || 0).toFixed(2).replace('.', ',')}}
                <i class="fa" :class="[
                  items.totais.pa_arrow,
                  items.totais.pa_arrow === 'fa-minus' ? 'text-warning' : (items.totais.pa_arrow === 'fa-arrow-up' ? 'text-success' : 'text-danger')
                ]" />
            </div>
            <div class="d-flex">
              <span class="ml-auto">Média Loja: {{ Number((items.totaisComparacao.pa) || 0).toFixed(2) }}</span>
            </div>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col cols="12" sm="6" lg="4">
        <b-card :no-body="true">
          <b-card-body class="clearfix">
            <i class="icon-up cadastros p-3 font-2xl mr-3 float-left float-up"></i>
            <div class="text-right mb-1 h5">Cadastros de Clientes</div>
            <div class="text-center mb-1 h5" :class="[
                  items.totais.clientes_arrow === 'fa-minus' ? '' : (items.totais.clientes_arrow === 'fa-arrow-up' ? 'text-success' : 'text-danger')
                ]">{{Number(items.totais.clientes || 0)}}
              <i class="fa" :class="[
                items.totais.clientes_arrow,
                items.totais.clientes_arrow === 'fa-minus' ? 'text-warning' : (items.totais.clientes_arrow === 'fa-arrow-up' ? 'text-success' : 'text-danger')
              ]" />
            </div>
            <div class="d-flex">
              <span class="ml-auto">Média Loja: {{ Number((items.totaisComparacao.clientes) || 0) | number }}</span>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col cols="12" sm="6" lg="4">
        <b-card :no-body="true">
          <b-card-body class="clearfix">
            <i class="icon-up taxa-conversao p-3 font-2xl mr-3 float-left float-up"></i>
            <div class="text-right mb-1 h5">Taxa de Conversão</div>
            <div class="text-center mb-1 h5" :class="[
                  items.totais.taxa_arrow === 'fa-minus' ? '' : (items.totais.taxa_arrow === 'fa-arrow-up' ? 'text-success' : 'text-danger')
                ]">{{ items.totais.taxa | number }}%
              <i class="fa" :class="[
                items.totais.taxa_arrow,
                items.totais.taxa_arrow === 'fa-minus' ? 'text-warning' : (items.totais.taxa_arrow === 'fa-arrow-up' ? 'text-success' : 'text-danger')
              ]" />
            </div>
            <div class="d-flex">
              <span class="ml-auto">Média Loja: {{ Number((items.totaisComparacao.taxa) || 0) | number }}%</span>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col cols="12" sm="6" lg="4">
        <b-card :no-body="true">
          <b-card-body class="clearfix">
            <i class="icon-up descontos p-3 font-2xl mr-3 float-left float-up"></i>
            <div class="text-right mb-1 h5">Descontos</div>
            <div class="text-center mb-1 h5" :class="[
                  items.totais.descontos_arrow === 'fa-minus' ? '' : (items.totais.descontos_arrow === 'fa-arrow-up' ? 'text-success' : 'text-danger')
                ]">{{ items.totais.descontos | currency }}
              <i class="fa" :class="[
                items.totais.descontos_arrow,
                items.totais.descontos_arrow === 'fa-minus' ? 'text-warning' : (items.totais.descontos_arrow === 'fa-arrow-up' ? 'text-success' : 'text-danger')
              ]" />
            </div>
            <div class="d-flex">
              <span class="ml-auto">Média Loja: {{ (items.totaisComparacao.descontos) | currency }}</span>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" sm="12" lg="12">
        <b-card header-tag="header">
          <div slot="header">
            <strong>Metas</strong>
          </div>
          <div class="alert alert-secondary text-center mb-0" v-if="metaData[0].records.length === 1 && !metaData[0].records[0].data_mov">
            Nenhum resultado encontrado.
          </div>
          <b-table responsive="lg" :items="metaData[0].records" :fields="fields.indicadores" v-if="metaData[0].records.length >= 1 && metaData[0].records[0].data_mov">
            <template slot="data_mov" slot-scope="data">
              {{ formatDate(data.item.data_mov) === 'Invalid date' ? '' : formatDate(data.item.data_mov) }}
            </template>
            <template slot="meta" slot-scope="data">
              <i class="fa fa-circle fa-lg" :class="getMeta(data.item.meta)"></i>
            </template>
            <template slot="vendas" slot-scope="data">
              {{ data.item.vendas | currency({symbol: ''}) }}
            </template>
            <template slot="vp" slot-scope="data">
              {{ data.item.vp | currency({symbol: ''}) }}
            </template>
            <template slot="va" slot-scope="data">
              {{ data.item.va | currency({symbol: ''}) }}
            </template>
            <template slot="descontos" slot-scope="data">
              {{ data.item.descontos | currency({symbol: ''}) }}
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { shuffleArray } from "@/shared/utils";
import cTable from "@/core/components/Table.vue";
import HorizontalBar from "@/core/components/charts/HorizontalBar";
import LineChart from "@/core/components/charts/LineChart";
import { hexToRgba } from "@coreui/coreui-pro/dist/js/coreui-utilities";
import moment from "moment";
import API from "@/core/api";
import session from "@/shared/session";
import { mes } from '@/shared/string';
import { setInterval } from 'timers';
import leftPad from 'left-pad';
import Gauge from '@/core/components/charts/Gauge';

export default {
  name: "Performance",
  components: {
    cTable,
    HorizontalBar,
    LineChart,
    Gauge
  },
  data: () => {
    return {
      filtro: {
        listas: {
          gestao: session.get("gestoes"),
          centres: session.get("lojas"),
          meses: [...Array(12).keys()].map(a => { return { value: a+1, text: mes(a+1) } }),
          anos: [],
          intervalo: []
        },
        fields: {
          periodo: 'Diaria',
          gestao: 0,
          centres: 0,
          dataDia: moment().format('YYYY-MM-DD'),
          dataIni: moment().format("YYYY-MM-DD"),
          dataFim: moment().format("YYYY-MM-DD"),
          mes: moment().format('M'),
          ano: moment().format('YYYY'),
          intervalo: {},
          desconsiderarPecas: false,
          considerarOutrasLojas: false
        }
      },
      metas: [],
      metaData: [
        {
          records:[]
        }
      ],
      corMetas: {
        bronze: 'rgb(153, 50, 0)',
        prata: 'rgb(156, 156, 156)',
        ouro: 'rgb(248, 203, 0)',
        diamante: 'rgb(32, 168, 216)'
      },
      items: {
        totais: {},
        totaisComparacao: {}
      },
      fields: {
        indicadores: [
          { key: "data_mov", label: "Data", sortable: true},
          { key: "meta", label: "Meta", class: "text-center" },
          {
            key: "vendas",
            label: "Vendas R$",
            sortable: true,
            currency: true,
            thClass: "text-right text-nowrap",
            tdClass: "text-right"
          },
          {
            key: "pecas",
            label: "Peças",
            sortable: true,
            number: true,
            thClass: "text-right",
            tdClass: "text-right",
            thStyle: { width: '100px'}
          },
          {
            key: "atendimentos",
            label: "Atend.",
            sortable: true,
            number: true,
            thClass: "text-right",
            tdClass: "text-right"
          },
          {
            key: "vp",
            label: "V/P R$",
            sortable: true,
            currency: true,
            thClass: "text-right text-nowrap",
            tdClass: "text-right"
          },
          {
            key: "va",
            label: "V/A R$",
            sortable: true,
            currency: true,
            thClass: "text-right text-nowrap",
            tdClass: "text-right"
          },
          {
            key: "pa",
            label: "P/A",
            sortable: true,
            number: true,
            thClass: "text-right",
            tdClass: "text-right"
          },
          {
            key: "clientes",
            label: "Clientes",
            sortable: true,
            number: true,
            thClass: "text-right",
            tdClass: "text-right"
          },
          {
            key: "conversao",
            label: "Tx. Conv. %",
            sortable: true,
            currency: true,
            thClass: "text-right text-nowrap",
            tdClass: "text-right"
          },
          {
            key: "descontos",
            label: "Descontos R$",
            sortable: true,
            currency: true,
            thClass: "text-right text-nowrap",
            tdClass: "text-right"
          }
        ]
      },
      isDisabledDataFim: true,
      valores: {
        metas: {
          bronze: 0,
          prata: 0,
          ouro: 0,
          diamante: 0
        },
        metaAtingida: {
          icon: '',
          valor: 0,
          diferenca: 0
        }
      },
      listPeriodos: [],
      userfoto: '',
      nomeFunc: '',
      vendedor_id: '',
      foto_vendedor: '',
      loja: '',
      loaded: false
    };
  },
  computed: {
    valorMetas: function() {
      return this.valores.metas;
    },
    metaAtingida: function() {
      return this.valores.metaAtingida;
    }
  },
  methods: {
    async search() {
      try {
        if (this.validaPeriodo() === true) {
          this.loaded = false;

          const performanceAPI = new API('api/performance');
          const {data} = await performanceAPI.get({ ...this.parseFilters(this.filtro.fields) });
          this.items = data;
          this.indicadorMedia(data)

          const metasAPI = new API('api/metas');
          const meta = await metasAPI.get({ ...this.parseFilters(this.filtro.fields) });
          this.meta = meta.data;

          const metasPorDataAPI = new API('api/metas-data');
          const metaVendData = await metasPorDataAPI.get({ ...this.parseFilters(this.filtro.fields) });
          this.metaData = metaVendData.data;
          this.definirMetaDia(metaVendData.data[0].records);
          this.loadCharts();
        }
      } catch (e) {
        console.error(e);
      }
    },
    getPhoto(foto) {
      const schema = session.get("schema");

      const hrefUrl = window.location.href;
      const partUrl = hrefUrl.split("/");
      const baseUrl = partUrl[0] + "//" + partUrl[2];

      return foto ? `${process.env.VUE_APP_API_URL}get-photo/${schema}/${foto}` : `${baseUrl}/img/avatars/no-photo.jpg`
    },
    getAvatar(e) {
      e.target.src = `${baseUrl}/img/avatars/no-photo.jpg`;
    },
    loadCharts() {
      this.calcValoresMetas();

      const {
        vendas,
        metas,
        metaAtingida
      } = this.valores;

      const newItems = [
        ...this.meta.map(i => i.records.map(r => {
          const { descricao, vendas, bronze, prata, ouro, diamante, qtd_funcionarios } = r;

          return {
            descricao,
            vendas,
            bronze: Number(bronze / qtd_funcionarios).toFixed(2),
            prata: Number(prata / qtd_funcionarios).toFixed(2),
            ouro: Number(ouro / qtd_funcionarios).toFixed(2),
            diamante: Number(diamante / qtd_funcionarios).toFixed(2)
          }
        }))[0]
      ];

      const { bronze, prata, ouro, diamante, qtdFunc } = this.valores.metas;
      const { vendas: gVendas, bronze: gBronze, prata: gPrata, ouro: gOuro, diamante: gDiamante } = this.valores.grafico.valores;
      const { vendas: cVendas, bronze: cBronze, prata: cPrata, ouro: cOuro, diamante: cDiamante } = this.valores.grafico.cores;

      this.charts = {
        MetaAtingida: {
          labels: ['Vendas', '(Bronze) Falta', '(Prata) Falta', '(Ouro) Falta', '(Diamante) Falta'],
          datasets: [
            {
              backgroundColor: [ cVendas, cBronze, cPrata, cOuro, cDiamante ],
              data: [ gVendas, gBronze, gPrata, gOuro, gDiamante ]
            }
          ]
        }
      }

      this.loaded = true
    },
    definirMetaDia(metas) {
      metas.map(e => {
        if (!e.vendas || !e.bronze || !e.prata || !e.ouro || !e.diamante) {
          e.meta = null
        } else {
          e.meta = Number(e.vendas) < Number(e.bronze) ? 0
            : Number(e.vendas) >= Number(e.bronze) && Number(e.vendas) < Number(e.prata) ? 1
              : Number(e.vendas) >= Number(e.prata) && Number(e.vendas) < Number(e.ouro) ? 2
                : Number(e.vendas) >= Number(e.ouro) && Number(e.vendas) < Number(e.diamante) ? 3
                  : Number(e.vendas) >= Number(e.diamante) ? 4 : null;
        }
        return e;
      });
    },
    indicadorMedia(data) {
      data.totais['pecas_arrow'] = Number(data.totais.pecas) === data.totaisComparacao.pecas ? 'fa-minus' : (Number(data.totais.pecas) > data.totaisComparacao.pecas ? 'fa-arrow-up' : 'fa-arrow-down')
      data.totais['atendimentos_arrow'] = Number(data.totais.atendimentos) === data.totaisComparacao.atendimentos ? 'fa-minus' : (Number(data.totais.atendimentos) > data.totaisComparacao.atendimentos ? 'fa-arrow-up' : 'fa-arrow-down')
      data.totais['vp_arrow'] = Number(data.totais.vp) === data.totaisComparacao.vp ? 'fa-minus' : (Number(data.totais.vp) > data.totaisComparacao.vp ? 'fa-arrow-up' : 'fa-arrow-down')
      data.totais['va_arrow'] = Number(data.totais.va) === data.totaisComparacao.va ? 'fa-minus' : (Number(data.totais.va) > data.totaisComparacao.va ? 'fa-arrow-up' : 'fa-arrow-down')
      data.totais['pa_arrow'] = Number(data.totais.pa) === data.totaisComparacao.pa ? 'fa-minus' : (Number(data.totais.pa) > data.totaisComparacao.pa ? 'fa-arrow-up' : 'fa-arrow-down')
      data.totais['clientes_arrow'] = Number(data.totais.clientes) === data.totaisComparacao.clientes ? 'fa-minus' : (Number(data.totais.clientes) > data.totaisComparacao.clientes ? 'fa-arrow-up' : 'fa-arrow-down')
      data.totais['conversao_arrow'] = Number(data.totais.conversao) === data.totaisComparacao.conversao ? 'fa-minus' : (Number(data.totais.conversao) > data.totaisComparacao.conversao ? 'fa-arrow-up' : 'fa-arrow-down')
      data.totais['descontos_arrow'] = Number(data.totais.descontos) === data.totaisComparacao.descontos ? 'fa-minus' : (Number(data.totais.descontos) > data.totaisComparacao.descontos ? 'fa-arrow-up' : 'fa-arrow-down')
      data.totais['taxa_arrow'] = Number(data.totais.taxa) === data.totaisComparacao.taxa ? 'fa-minus' : (Number(data.totais.taxa) > data.totaisComparacao.taxa ? 'fa-arrow-up' : 'fa-arrow-down')
      data.totais['tempo_medio_arrow'] = Number(data.totais.tempo_medio) === data.totaisComparacao.tempo_medio ? 'fa-minus' : (Number(data.totais.tempo_medio) > data.totaisComparacao.tempo_medio ? 'fa-arrow-up' : 'fa-arrow-down')
    },
    calcValoresMetas() {
      const arMetas = ['', 'bronze', 'prata', 'ouro', 'diamante', ''];
      let meta = '';
      let vendas = 0;
      let metas = {
        bronze: 0,
        prata: 0,
        ouro: 0,
        diamante: 0
      }
      let grafico = {
        valores: {
          vendas: 0,
          bronze: 0,
          prata: 0,
          ouro: 0,
          diamante: 0
        },
        cores: {
          vendas: '#FF704A',
          bronze: 'rgb(153, 50, 0, .2)',
          prata: 'rgb(156, 156, 156, .2)',
          ouro: 'rgb(248, 203, 0, .2)',
          diamante: 'rgb(32, 168, 216, .2)'
        }
      }

      if (this.meta.every(i => i.records.length > 0)) {
        vendas = this.meta.map(i => i.records.map(r => r.vendas).reduce((total, current) => Number(total) + Number(current)))[0];

        metas.bronze = this.meta.map(i => i.records.map(r => r.bronze / r.qtd_funcionarios).reduce((total, current) => Number(total) + Number(current)))[0];
        metas.prata = this.meta.map(i => i.records.map(r => r.prata / r.qtd_funcionarios).reduce((total, current) => Number(total) + Number(current)))[0];
        metas.ouro = this.meta.map(i => i.records.map(r => r.ouro / r.qtd_funcionarios).reduce((total, current) => Number(total) + Number(current)))[0];
        metas.diamante = this.meta.map(i => i.records.map(r => r.diamante / r.qtd_funcionarios).reduce((total, current) => Number(total) + Number(current)))[0];

        meta = Number(vendas) < Number(metas.bronze) ? ''
          : Number(vendas) >= Number(metas.bronze) && Number(vendas) < Number(metas.prata) ? 'bronze'
            : Number(vendas) >= Number(metas.prata) && Number(vendas) < Number(metas.ouro) ? 'prata'
              : Number(vendas) >= Number(metas.ouro) && Number(vendas) < Number(metas.diamante) ? 'ouro'
                : 'diamante';

        grafico.valores = {
          vendas: vendas,
          bronze: metas.bronze,
          prata: metas.prata,
          ouro: metas.ouro,
          diamante: metas.diamante
        }

        if (Number(vendas) < Number(metas.bronze)) {
          grafico.valores.bronze = Number(metas.bronze) - Number(vendas)
          grafico.valores.prata = Number(metas.prata) - Number(vendas)
          grafico.valores.ouro = Number(metas.ouro) - Number(vendas)
          grafico.valores.diamante = Number(metas.diamante) - Number(vendas)
        } else if (Number(vendas) >= Number(metas.bronze) && Number(vendas) < Number(metas.prata)) {
          grafico.valores.bronze = 0
          grafico.valores.prata = Number(metas.prata) - Number(vendas)
          grafico.valores.ouro = Number(metas.ouro) - Number(vendas)
          grafico.valores.diamante = Number(metas.diamante) - Number(vendas)
          grafico.cores.vendas = this.corMetas.bronze
        } else if (Number(vendas) >= Number(metas.prata) && Number(vendas) < Number(metas.ouro)) {
          grafico.valores.bronze = 0
          grafico.valores.prata = 0
          grafico.valores.ouro = Number(metas.ouro) - Number(vendas)
          grafico.valores.diamante = Number(metas.diamante) - Number(vendas)
          grafico.cores.vendas = this.corMetas.prata
        } else if (Number(vendas) >= Number(metas.ouro) && Number(vendas) < Number(metas.diamante)) {
          grafico.valores.bronze = 0
          grafico.valores.prata = 0
          grafico.valores.ouro = 0
          grafico.valores.diamante = Number(metas.diamante) - Number(vendas)
          grafico.cores.vendas = this.corMetas.ouro
        } else {
          grafico.valores.bronze = 0
          grafico.valores.prata = 0
          grafico.valores.ouro = 0
          grafico.valores.diamante = 0
          grafico.cores.vendas = this.corMetas.diamante
        }
      }

      this.valores.grafico = grafico;
      this.valores.vendas = vendas;
      this.valores.metas = metas;
      this.valores.metaAtingida = {
        icon: meta,
        diferenca: meta !== '' ? vendas - (metas[meta] || 0) : vendas - metas.bronze
      };
    },
    getMeta(meta) {
      return meta === 1 ? 'meta-bronze'
        : meta === 2 ? 'meta-prata'
          : meta === 3 ? 'text-warning'
            : meta === 4 ? 'text-info' : 'fa-frown-o'
    },
    formatDate: function(data)  {
        return moment(String(data)).format('DD/MM/YYYY')
    },
    validaPeriodo() {
      const { periodo, dataDia } = this.filtro.fields;
      if (periodo !== 'Diaria') {
        return true;
      }

      let invalid = false;

      if (dataDia === '' || !moment(dataDia).isValid()) {
        invalid = true;
      }

      if (invalid) {
        return swal({
          text: 'Data inválida.'
        });
      }
      return true
    },
    dataChanged() {
      this.filtro.fields.periodo = "Periodo";
    },
    async getAnosPeriodo() {
      const anosMetasAPI = new API('api/anos-periodos');
      const listAnosMetasNps = await anosMetasAPI.get({ ...this.parseFilters(this.filtro.fields) });
      const { data : { listAnosMetas : anos } } = listAnosMetasNps;

      if (anos.length > 0) {
        this.filtro.listas.anos = anos.map(d => {
          return {
            value: d.periodos_anuais,
            text: d.periodos_anuais
          }
        });
      }
    },
    async getListPeriodosSemanal() {
      const periodosAPI = new API('api/periodos-metas');
      this.listPeriodos = await periodosAPI.get({ ...this.parseFilters(this.filtro.fields) });
    },
    setDatasIntervalo() {
      const {dataIni, dataFim} = this.filtro.fields.intervalo;
      this.filtro.fields.dataIni = dataIni;
      this.filtro.fields.dataFim = dataFim;
    },
    async setIntervalo() {
      if (this.filtro.fields.periodo === 'Semanal') {
        this.filtro.fields.dataIni = null;
        this.filtro.fields.dataFim = null;
        this.filtro.fields.intervalo = [];
        this.filtro.listas.intervalo = [];
        
        await this.getListPeriodosSemanal()
        if (this.listPeriodos.data.length > 0) {
          this.filtro.listas.intervalo = this.listPeriodos.data;
          const { mes, ano } = this.filtro.fields;

          if (Number(mes) !== moment().get('month')) {
            const find = moment().set('month', leftPad(Number(mes)-1, 2, '0')).set('year', Number(ano))

            for(const i in this.listPeriodos.data) {
              const intervalo = this.listPeriodos.data[i].value;
              const intervaloIni = moment(intervalo.dataIni)
              const intervaloFim = moment(intervalo.dataFim)
              if (find.isBetween(intervaloIni, intervaloFim, 'days', '[]')) {
                this.filtro.fields.intervalo = intervalo;
              }
            }
          } else {
            this.filtro.fields.intervalo = this.listPeriodos.data[0].value
          }

          this.setDatasIntervalo()
        } else {
          swal({
            title: 'Informação',
            text: 'Nenhum intervalo de metas encontrado.',
            icon: 'info',
            dangerMode: true
          });
        }
      } else if (this.filtro.fields.periodo === 'Mensal') {
        const { mes, ano } = this.filtro.fields;
        const data = moment().set('month', leftPad(Number(mes)-1, 2, '0')).set('year', Number(ano));
        this.filtro.fields.dataIni = data.startOf('month').format('YYYY-MM-DD');
        this.filtro.fields.dataFim = data.endOf('month').format('YYYY-MM-DD');
      }
    },
    async consultaPeriodo(periodo) {
      this.filtro.fields.intervalo = [];

      switch(periodo) {
        case 'Diaria': {
          this.filtro.fields.dataIni = moment().format('YYYY-MM-DD');
          this.filtro.fields.dataFim = moment().format('YYYY-MM-DD');
          break;
        }
        case 'Semanal': {
          await this.getListPeriodosSemanal()
          await this.setIntervalo()
          break;
        }
        case 'Mensal': {
          const { mes, ano } = this.filtro.fields;
          const data = moment().set('month', leftPad(Number(mes)-1, 2, '0')).set('year', Number(ano));
          this.filtro.fields.dataIni = data.startOf('month').format('YYYY-MM-DD');
          this.filtro.fields.dataFim = data.endOf('month').format('YYYY-MM-DD');
          this.filtro.fields.periodo = 'Mensal'
          break;
        }
        default: {
          return;
        }
      }

      this.search();
    },
    parseFilters(filters) {
      let filter = { ...filters };
      const {
        centres,
        periodo
      } = filters;

      if (periodo === 'Diaria') {
        filter.dataIni = filter.dataDia;
        filter.dataFim = filter.dataDia;
      }

      filter.centres =  this.loja_id;
      filter.vendedor = this.vendedor_id ;
      filter.vendedorFilter = this.vendedor_id ;
      filter.listaPorVendedor = true;
      return filter;
    },
    fecharPerformance() {
      return this.$router.push({path: '/lista-vez'});
    },
    export2image: function(id, name) {
      // Set White Background
      let chart = document.getElementById(id);
      var ctx = chart.getContext("2d");
      ctx.save();
      ctx.globalCompositeOperation = "destination-over";
      ctx.fillStyle = "#FFF";
      ctx.fillRect(0, 0, chart.width, chart.height);
      ctx.restore();

      // Download image
      let canvas = document.getElementById(id).toDataURL("image/png");
      let link = document.createElement("a");
      link.download = name || "Grafico";
      link.href = canvas;
      link.click();
    }
  },
  async created() {
    const foto = window.atob(this.$route.params.foto);

    this.vendedor_id = window.atob(this.$route.params.vendedor_id);
    this.loja_id = window.atob(this.$route.params.loja);
    this.foto_vendedor = (foto && foto != 'null' ? foto : null);
    this.nomeFunc = window.atob(this.$route.params.vendedor_nome);
    await this.getAnosPeriodo();
    this.search();
  }
};
</script>

<style scoped>
  .avatar-center {
    margin: 0px auto;
    position: relative;
  }
</style>
