var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "animated fadeIn" },
    [
      _c(
        "b-card",
        { attrs: { "header-tag": "header", "footer-tag": "footer" } },
        [
          _c(
            "div",
            { attrs: { slot: "header" }, slot: "header" },
            [
              _c("strong", [_vm._v("Performance")]),
              _c(
                "b-button",
                {
                  staticClass: "my-2 my-sm-0 text-white",
                  staticStyle: { float: "right", "border-radius": "25px" },
                  attrs: { variant: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.fecharPerformance()
                    }
                  }
                },
                [_c("i", { staticClass: "fa fa-close" })]
              )
            ],
            1
          ),
          _c(
            "b-card",
            {
              staticClass: "col-md-12 p-0 mr-2",
              attrs: { "header-tag": "header", "footer-tag": "footer" }
            },
            [
              _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                _c("strong", [_vm._v(" Filtros ")])
              ]),
              _c(
                "div",
                { staticClass: "p-1 mt-2" },
                [
                  _c(
                    "b-row",
                    { staticClass: "mb-1" },
                    [
                      _c(
                        "b-col",
                        {
                          staticClass: "d-md-block",
                          attrs: { sm: "12", md: "4" }
                        },
                        [
                          _c(
                            "b-button-toolbar",
                            { staticClass: "float-left" },
                            [
                              _c(
                                "b-form-radio-group",
                                {
                                  staticClass: "mr-3",
                                  attrs: {
                                    buttons: "",
                                    "button-variant": "outline-primary",
                                    name: "radiosBtn"
                                  },
                                  on: { change: _vm.consultaPeriodo },
                                  model: {
                                    value: _vm.filtro.fields.periodo,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.filtro.fields,
                                        "periodo",
                                        $$v
                                      )
                                    },
                                    expression: "filtro.fields.periodo"
                                  }
                                },
                                [
                                  _c(
                                    "b-form-radio",
                                    {
                                      staticClass: "mx-0",
                                      attrs: { value: "Diaria" }
                                    },
                                    [_vm._v("Diária")]
                                  ),
                                  _c(
                                    "b-form-radio",
                                    {
                                      staticClass: "mx-0",
                                      attrs: { value: "Semanal" }
                                    },
                                    [_vm._v("Semanal")]
                                  ),
                                  _c(
                                    "b-form-radio",
                                    {
                                      staticClass: "mx-0",
                                      attrs: { value: "Mensal" }
                                    },
                                    [_vm._v("Mensal")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("b-col", { attrs: { sm: "12", md: "8" } }, [
                        _c(
                          "div",
                          {
                            staticClass: "custom-control custom-checkbox pt-1"
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    _vm.filtro.fields.considerarOutrasLojas,
                                  expression:
                                    "filtro.fields.considerarOutrasLojas"
                                }
                              ],
                              staticClass: "custom-control-input",
                              attrs: {
                                type: "checkbox",
                                id: "cbConsiderarOutrasLojas"
                              },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.filtro.fields.considerarOutrasLojas
                                )
                                  ? _vm._i(
                                      _vm.filtro.fields.considerarOutrasLojas,
                                      null
                                    ) > -1
                                  : _vm.filtro.fields.considerarOutrasLojas
                              },
                              on: {
                                change: function($event) {
                                  var $$a =
                                      _vm.filtro.fields.considerarOutrasLojas,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.filtro.fields,
                                          "considerarOutrasLojas",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.filtro.fields,
                                          "considerarOutrasLojas",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.filtro.fields,
                                      "considerarOutrasLojas",
                                      $$c
                                    )
                                  }
                                }
                              }
                            }),
                            _c(
                              "label",
                              {
                                staticClass: "custom-control-label",
                                attrs: { for: "cbConsiderarOutrasLojas" }
                              },
                              [_vm._v("Considerar vendas de outras lojas")]
                            )
                          ]
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mb-1" },
                    [
                      _vm.filtro.fields.periodo === "Diaria"
                        ? _c(
                            "b-col",
                            { attrs: { sm: "4" } },
                            [
                              _c("b-form-input", {
                                attrs: { plain: true, type: "date" },
                                model: {
                                  value: _vm.filtro.fields.dataDia,
                                  callback: function($$v) {
                                    _vm.$set(_vm.filtro.fields, "dataDia", $$v)
                                  },
                                  expression: "filtro.fields.dataDia"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.filtro.fields.periodo !== "Diaria"
                        ? _c(
                            "b-col",
                            {
                              staticClass: "pr-md-0 mb-1 mb-md-0",
                              attrs: { sm: "3" }
                            },
                            [
                              _c("b-form-select", {
                                attrs: {
                                  plain: true,
                                  options: _vm.filtro.listas.meses
                                },
                                on: { change: _vm.setIntervalo },
                                model: {
                                  value: _vm.filtro.fields.mes,
                                  callback: function($$v) {
                                    _vm.$set(_vm.filtro.fields, "mes", $$v)
                                  },
                                  expression: "filtro.fields.mes"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.filtro.fields.periodo !== "Diaria"
                        ? _c(
                            "b-col",
                            { attrs: { sm: "2" } },
                            [
                              _c("b-form-select", {
                                staticStyle: { width: "78px" },
                                attrs: {
                                  plain: true,
                                  options: _vm.filtro.listas.anos
                                },
                                on: { change: _vm.setIntervalo },
                                model: {
                                  value: _vm.filtro.fields.ano,
                                  callback: function($$v) {
                                    _vm.$set(_vm.filtro.fields, "ano", $$v)
                                  },
                                  expression: "filtro.fields.ano"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "b-col",
                        {
                          staticClass: "pr-md-0",
                          attrs: {
                            sm: ["Semanal", "Mensal"].includes(
                              _vm.filtro.fields.periodo
                            )
                              ? 5
                              : 6
                          }
                        },
                        [
                          _c("b-form-select", {
                            staticStyle: { width: "232px" },
                            attrs: {
                              plain: true,
                              options: _vm.filtro.listas.intervalo,
                              disabled: _vm.filtro.fields.periodo !== "Semanal"
                            },
                            on: { change: _vm.setDatasIntervalo },
                            model: {
                              value: _vm.filtro.fields.intervalo,
                              callback: function($$v) {
                                _vm.$set(_vm.filtro.fields, "intervalo", $$v)
                              },
                              expression: "filtro.fields.intervalo"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { sm: "1" } },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "my-2 my-sm-0 text-white",
                              class: { "btn-block": _vm.$mq === "sm" },
                              attrs: { variant: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.search()
                                }
                              }
                            },
                            [_c("i", { staticClass: "fa fa-search" })]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { xs: "12", sm: "12", lg: "4" } },
            [
              _c(
                "b-card",
                { attrs: { "no-body": true } },
                [
                  _c(
                    "b-card-body",
                    { staticClass: "clearfix" },
                    [
                      _c(
                        "b-row",
                        { staticClass: "mt-4" },
                        [
                          _c(
                            "b-col",
                            {
                              staticClass: "avatar-center",
                              attrs: { cols: "12", sm: "6", lg: "6" }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "d-flex",
                                  staticStyle: { height: "134px" }
                                },
                                [
                                  _c("img", {
                                    staticClass:
                                      "img-responsive img-circle img-avatar avatar-center",
                                    staticStyle: {
                                      height: "125px",
                                      width: "125px"
                                    },
                                    attrs: {
                                      src: _vm.getPhoto(_vm.foto_vendedor)
                                    },
                                    on: { error: _vm.getAvatar }
                                  })
                                ]
                              ),
                              _c("div", { staticClass: "d-flex" }, [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "font-weight-bold avatar-center"
                                  },
                                  [_vm._v(_vm._s(_vm.nomeFunc))]
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { xs: "12", sm: "12", lg: "8" } },
            [
              _c(
                "b-card",
                { attrs: { "no-body": true } },
                [
                  _c(
                    "b-card-body",
                    { staticClass: "clearfix" },
                    [
                      _c("div", { staticClass: "text-left mb-0 h5" }, [
                        _vm._v("Metas")
                      ]),
                      _c(
                        "b-row",
                        { staticClass: "mt-4" },
                        [
                          _c(
                            "b-col",
                            { attrs: { cols: "12", sm: "6", lg: "6" } },
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex" },
                                [
                                  _vm.loaded
                                    ? _c("gauge", {
                                        staticClass: "w-100",
                                        attrs: {
                                          chartdata: _vm.charts.MetaAtingida,
                                          height: 134,
                                          chartId: "chart-gauge-01"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ]
                          ),
                          _c(
                            "b-col",
                            {
                              staticClass: "pt-4",
                              attrs: { cols: "12", sm: "6", lg: "6" }
                            },
                            [
                              _c("div", { staticClass: "d-flex" }, [
                                _c("span", { staticClass: "text-muted" }, [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "vertical-align": "middle",
                                        "margin-bottom": "2px",
                                        "font-weight": "bold"
                                      }
                                    },
                                    [_vm._v("   Venda Realizada")]
                                  )
                                ]),
                                _c(
                                  "span",
                                  {
                                    staticClass: "ml-auto",
                                    staticStyle: { "font-weight": "bold" }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("currency")(
                                          _vm.items.totais.vendas
                                        )
                                      )
                                    )
                                  ]
                                )
                              ]),
                              _c("div", { staticClass: "d-flex" }, [
                                _c("span", { staticClass: "text-muted" }, [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "badge badge-meta-bronze",
                                      staticStyle: {
                                        "vertical-align": "middle",
                                        "margin-bottom": "2px"
                                      }
                                    },
                                    [_vm._v("  ")]
                                  ),
                                  _vm._v(" Meta Bronze\n                ")
                                ]),
                                _c("span", { staticClass: "ml-auto" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("currency")(_vm.valorMetas.bronze)
                                    )
                                  )
                                ])
                              ]),
                              _c("div", { staticClass: "d-flex" }, [
                                _c("span", { staticClass: "text-muted" }, [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "badge badge-meta-prata",
                                      staticStyle: {
                                        "vertical-align": "middle",
                                        "margin-bottom": "2px"
                                      }
                                    },
                                    [_vm._v("  ")]
                                  ),
                                  _vm._v(" Meta Prata\n                ")
                                ]),
                                _c("span", { staticClass: "ml-auto" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("currency")(_vm.valorMetas.prata)
                                    )
                                  )
                                ])
                              ]),
                              _c("div", { staticClass: "d-flex" }, [
                                _c("span", { staticClass: "text-muted" }, [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "badge badge-meta-ouro",
                                      staticStyle: {
                                        "vertical-align": "middle",
                                        "margin-bottom": "2px"
                                      }
                                    },
                                    [_vm._v("  ")]
                                  ),
                                  _vm._v(" Meta Ouro\n                ")
                                ]),
                                _c("span", { staticClass: "ml-auto" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("currency")(_vm.valorMetas.ouro)
                                    )
                                  )
                                ])
                              ]),
                              _c("div", { staticClass: "d-flex" }, [
                                _c("span", { staticClass: "text-muted" }, [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "badge badge-meta-diamante",
                                      staticStyle: {
                                        "vertical-align": "middle",
                                        "margin-bottom": "2px"
                                      }
                                    },
                                    [_vm._v("  ")]
                                  ),
                                  _vm._v(" Meta Diamante\n                ")
                                ]),
                                _c("span", { staticClass: "ml-auto" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("currency")(
                                        _vm.valorMetas.diamante
                                      )
                                    )
                                  )
                                ])
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "6", lg: "4" } },
            [
              _c(
                "b-card",
                { attrs: { "no-body": true } },
                [
                  _c("b-card-body", { staticClass: "clearfix" }, [
                    _c("i", {
                      staticClass:
                        "icon-up pecas p-2 font-2xl mr-2 float-left float-up"
                    }),
                    _c("div", { staticClass: "text-right mb-1 h5" }, [
                      _vm._v("Tempo médio de espera")
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "text-center mb-1 h5",
                        class: [
                          _vm.items.totais.tempo_medio_arrow === "fa-minus"
                            ? ""
                            : _vm.items.totais.tempo_medio_arrow ===
                              "fa-arrow-up"
                            ? "text-success"
                            : "text-danger"
                        ]
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm._f("number")(_vm.items.totais.tempo_medio)
                          ) + "\n              "
                        ),
                        _c("i", {
                          staticClass: "fa",
                          class: [
                            _vm.items.totais.tempo_medio_arrow,
                            _vm.items.totais.tempo_medio_arrow === "fa-minus"
                              ? "text-warning"
                              : _vm.items.totais.tempo_medio_arrow ===
                                "fa-arrow-up"
                              ? "text-success"
                              : "text-danger"
                          ]
                        })
                      ]
                    ),
                    _c("div", { staticClass: "d-flex" }, [
                      _c("span", { staticClass: "ml-auto" }, [
                        _vm._v(
                          "Média Loja: " +
                            _vm._s(
                              _vm._f("number")(
                                _vm.items.totaisComparacao.tempo_medio
                              )
                            )
                        )
                      ])
                    ])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "6", lg: "4" } },
            [
              _c(
                "b-card",
                { attrs: { "no-body": true } },
                [
                  _c("b-card-body", { staticClass: "clearfix" }, [
                    _c("i", {
                      staticClass:
                        "icon-up pecas p-3 font-2xl mr-3 float-left float-up"
                    }),
                    _c("div", { staticClass: "text-right mb-1 h5" }, [
                      _vm._v("Peças")
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "text-center mb-1 h5",
                        class: [
                          _vm.items.totais.pecas_arrow === "fa-minus"
                            ? ""
                            : _vm.items.totais.pecas_arrow === "fa-arrow-up"
                            ? "text-success"
                            : "text-danger"
                        ]
                      },
                      [
                        _vm._v(
                          _vm._s(_vm._f("number")(_vm.items.totais.pecas)) +
                            "\n              "
                        ),
                        _c("i", {
                          staticClass: "fa",
                          class: [
                            _vm.items.totais.pecas_arrow,
                            _vm.items.totais.pecas_arrow === "fa-minus"
                              ? "text-warning"
                              : _vm.items.totais.pecas_arrow === "fa-arrow-up"
                              ? "text-success"
                              : "text-danger"
                          ]
                        })
                      ]
                    ),
                    _c("div", { staticClass: "d-flex" }, [
                      _c("span", { staticClass: "ml-auto" }, [
                        _vm._v(
                          "Média Loja: " +
                            _vm._s(
                              _vm._f("number")(_vm.items.totaisComparacao.pecas)
                            )
                        )
                      ])
                    ])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "6", lg: "4" } },
            [
              _c(
                "b-card",
                { attrs: { "no-body": true } },
                [
                  _c("b-card-body", { staticClass: "clearfix" }, [
                    _c("i", {
                      staticClass:
                        "icon-up atendimentos p-3 font-2xl mr-3 float-left float-up"
                    }),
                    _c("div", { staticClass: "text-right mb-1 h5" }, [
                      _vm._v("Atendimentos")
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "text-center mb-1 h5",
                        class: [
                          _vm.items.totais.atendimentos_arrow === "fa-minus"
                            ? ""
                            : _vm.items.totais.atendimentos_arrow ===
                              "fa-arrow-up"
                            ? "text-success"
                            : "text-danger"
                        ]
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm._f("number")(_vm.items.totais.atendimentos)
                          ) + "\n              "
                        ),
                        _c("i", {
                          staticClass: "fa",
                          class: [
                            _vm.items.totais.atendimentos_arrow,
                            _vm.items.totais.atendimentos_arrow === "fa-minus"
                              ? "text-warning"
                              : _vm.items.totais.atendimentos_arrow ===
                                "fa-arrow-up"
                              ? "text-success"
                              : "text-danger"
                          ]
                        })
                      ]
                    ),
                    _c("div", { staticClass: "d-flex" }, [
                      _c("span", { staticClass: "ml-auto" }, [
                        _vm._v(
                          "Média Loja: " +
                            _vm._s(
                              _vm._f("number")(
                                _vm.items.totaisComparacao.atendimentos
                              )
                            )
                        )
                      ])
                    ])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "6", lg: "4" } },
            [
              _c(
                "b-card",
                { attrs: { "no-body": true } },
                [
                  _c("b-card-body", { staticClass: "clearfix" }, [
                    _c("i", {
                      staticClass:
                        "icon-up vendas-pecas p-3 font-2xl mr-3 float-left float-up"
                    }),
                    _c("div", { staticClass: "text-right mb-1 h5" }, [
                      _vm._v("Vendas/Peças")
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "text-center mb-1 h5",
                        class: [
                          _vm.items.totais.vp_arrow === "fa-minus"
                            ? ""
                            : _vm.items.totais.vp_arrow === "fa-arrow-up"
                            ? "text-success"
                            : "text-danger"
                        ]
                      },
                      [
                        _vm._v(
                          _vm._s(_vm._f("currency")(_vm.items.totais.vp)) +
                            "\n              "
                        ),
                        _c("i", {
                          staticClass: "fa",
                          class: [
                            _vm.items.totais.vp_arrow,
                            _vm.items.totais.vp_arrow === "fa-minus"
                              ? "text-warning"
                              : _vm.items.totais.vp_arrow === "fa-arrow-up"
                              ? "text-success"
                              : "text-danger"
                          ]
                        })
                      ]
                    ),
                    _c("div", { staticClass: "d-flex" }, [
                      _c("span", { staticClass: "ml-auto" }, [
                        _vm._v(
                          "Média Loja: " +
                            _vm._s(
                              _vm._f("currency")(_vm.items.totaisComparacao.vp)
                            )
                        )
                      ])
                    ])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "6", lg: "4" } },
            [
              _c(
                "b-card",
                { attrs: { "no-body": true } },
                [
                  _c("b-card-body", { staticClass: "clearfix" }, [
                    _c("i", {
                      staticClass:
                        "icon-up vendas-atendimentos p-3 font-2xl mr-3 float-left float-up"
                    }),
                    _c("div", { staticClass: "text-right mb-1 h5" }, [
                      _vm._v("Vendas/Atendimentos")
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "text-center mb-1 h5",
                        class: [
                          _vm.items.totais.va_arrow === "fa-minus"
                            ? ""
                            : _vm.items.totais.va_arrow === "fa-arrow-up"
                            ? "text-success"
                            : "text-danger"
                        ]
                      },
                      [
                        _vm._v(
                          _vm._s(_vm._f("currency")(_vm.items.totais.va)) +
                            "\n              "
                        ),
                        _c("i", {
                          staticClass: "fa",
                          class: [
                            _vm.items.totais.va_arrow,
                            _vm.items.totais.va_arrow === "fa-minus"
                              ? "text-warning"
                              : _vm.items.totais.va_arrow === "fa-arrow-up"
                              ? "text-success"
                              : "text-danger"
                          ]
                        })
                      ]
                    ),
                    _c("div", { staticClass: "d-flex" }, [
                      _c("span", { staticClass: "ml-auto" }, [
                        _vm._v(
                          "Média Loja: " +
                            _vm._s(
                              _vm._f("currency")(_vm.items.totaisComparacao.va)
                            )
                        )
                      ])
                    ])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "6", lg: "4" } },
            [
              _c(
                "b-card",
                { attrs: { "no-body": true } },
                [
                  _c("b-card-body", { staticClass: "clearfix" }, [
                    _c("i", {
                      staticClass:
                        "icon-up pecas-atendimentos p-3 font-2xl mr-3 float-left float-up"
                    }),
                    _c("div", { staticClass: "text-right mb-1 h5" }, [
                      _vm._v("Peças/Atendimentos")
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "text-center mb-1 h5",
                        class: [
                          _vm.items.totais.pa_arrow === "fa-minus"
                            ? ""
                            : _vm.items.totais.pa_arrow === "fa-arrow-up"
                            ? "text-success"
                            : "text-danger"
                        ]
                      },
                      [
                        _vm._v(
                          _vm._s(
                            Number(_vm.items.totais.pa || 0)
                              .toFixed(2)
                              .replace(".", ",")
                          ) + "\n              "
                        ),
                        _c("i", {
                          staticClass: "fa",
                          class: [
                            _vm.items.totais.pa_arrow,
                            _vm.items.totais.pa_arrow === "fa-minus"
                              ? "text-warning"
                              : _vm.items.totais.pa_arrow === "fa-arrow-up"
                              ? "text-success"
                              : "text-danger"
                          ]
                        })
                      ]
                    ),
                    _c("div", { staticClass: "d-flex" }, [
                      _c("span", { staticClass: "ml-auto" }, [
                        _vm._v(
                          "Média Loja: " +
                            _vm._s(
                              Number(
                                _vm.items.totaisComparacao.pa || 0
                              ).toFixed(2)
                            )
                        )
                      ])
                    ])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "6", lg: "4" } },
            [
              _c(
                "b-card",
                { attrs: { "no-body": true } },
                [
                  _c("b-card-body", { staticClass: "clearfix" }, [
                    _c("i", {
                      staticClass:
                        "icon-up cadastros p-3 font-2xl mr-3 float-left float-up"
                    }),
                    _c("div", { staticClass: "text-right mb-1 h5" }, [
                      _vm._v("Cadastros de Clientes")
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "text-center mb-1 h5",
                        class: [
                          _vm.items.totais.clientes_arrow === "fa-minus"
                            ? ""
                            : _vm.items.totais.clientes_arrow === "fa-arrow-up"
                            ? "text-success"
                            : "text-danger"
                        ]
                      },
                      [
                        _vm._v(
                          _vm._s(Number(_vm.items.totais.clientes || 0)) +
                            "\n            "
                        ),
                        _c("i", {
                          staticClass: "fa",
                          class: [
                            _vm.items.totais.clientes_arrow,
                            _vm.items.totais.clientes_arrow === "fa-minus"
                              ? "text-warning"
                              : _vm.items.totais.clientes_arrow ===
                                "fa-arrow-up"
                              ? "text-success"
                              : "text-danger"
                          ]
                        })
                      ]
                    ),
                    _c("div", { staticClass: "d-flex" }, [
                      _c("span", { staticClass: "ml-auto" }, [
                        _vm._v(
                          "Média Loja: " +
                            _vm._s(
                              _vm._f("number")(
                                Number(_vm.items.totaisComparacao.clientes || 0)
                              )
                            )
                        )
                      ])
                    ])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "6", lg: "4" } },
            [
              _c(
                "b-card",
                { attrs: { "no-body": true } },
                [
                  _c("b-card-body", { staticClass: "clearfix" }, [
                    _c("i", {
                      staticClass:
                        "icon-up taxa-conversao p-3 font-2xl mr-3 float-left float-up"
                    }),
                    _c("div", { staticClass: "text-right mb-1 h5" }, [
                      _vm._v("Taxa de Conversão")
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "text-center mb-1 h5",
                        class: [
                          _vm.items.totais.taxa_arrow === "fa-minus"
                            ? ""
                            : _vm.items.totais.taxa_arrow === "fa-arrow-up"
                            ? "text-success"
                            : "text-danger"
                        ]
                      },
                      [
                        _vm._v(
                          _vm._s(_vm._f("number")(_vm.items.totais.taxa)) +
                            "%\n            "
                        ),
                        _c("i", {
                          staticClass: "fa",
                          class: [
                            _vm.items.totais.taxa_arrow,
                            _vm.items.totais.taxa_arrow === "fa-minus"
                              ? "text-warning"
                              : _vm.items.totais.taxa_arrow === "fa-arrow-up"
                              ? "text-success"
                              : "text-danger"
                          ]
                        })
                      ]
                    ),
                    _c("div", { staticClass: "d-flex" }, [
                      _c("span", { staticClass: "ml-auto" }, [
                        _vm._v(
                          "Média Loja: " +
                            _vm._s(
                              _vm._f("number")(
                                Number(_vm.items.totaisComparacao.taxa || 0)
                              )
                            ) +
                            "%"
                        )
                      ])
                    ])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "6", lg: "4" } },
            [
              _c(
                "b-card",
                { attrs: { "no-body": true } },
                [
                  _c("b-card-body", { staticClass: "clearfix" }, [
                    _c("i", {
                      staticClass:
                        "icon-up descontos p-3 font-2xl mr-3 float-left float-up"
                    }),
                    _c("div", { staticClass: "text-right mb-1 h5" }, [
                      _vm._v("Descontos")
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "text-center mb-1 h5",
                        class: [
                          _vm.items.totais.descontos_arrow === "fa-minus"
                            ? ""
                            : _vm.items.totais.descontos_arrow === "fa-arrow-up"
                            ? "text-success"
                            : "text-danger"
                        ]
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm._f("currency")(_vm.items.totais.descontos)
                          ) + "\n            "
                        ),
                        _c("i", {
                          staticClass: "fa",
                          class: [
                            _vm.items.totais.descontos_arrow,
                            _vm.items.totais.descontos_arrow === "fa-minus"
                              ? "text-warning"
                              : _vm.items.totais.descontos_arrow ===
                                "fa-arrow-up"
                              ? "text-success"
                              : "text-danger"
                          ]
                        })
                      ]
                    ),
                    _c("div", { staticClass: "d-flex" }, [
                      _c("span", { staticClass: "ml-auto" }, [
                        _vm._v(
                          "Média Loja: " +
                            _vm._s(
                              _vm._f("currency")(
                                _vm.items.totaisComparacao.descontos
                              )
                            )
                        )
                      ])
                    ])
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "12", lg: "12" } },
            [
              _c(
                "b-card",
                { attrs: { "header-tag": "header" } },
                [
                  _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                    _c("strong", [_vm._v("Metas")])
                  ]),
                  _vm.metaData[0].records.length === 1 &&
                  !_vm.metaData[0].records[0].data_mov
                    ? _c(
                        "div",
                        {
                          staticClass: "alert alert-secondary text-center mb-0"
                        },
                        [
                          _vm._v(
                            "\n          Nenhum resultado encontrado.\n        "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm.metaData[0].records.length >= 1 &&
                  _vm.metaData[0].records[0].data_mov
                    ? _c("b-table", {
                        attrs: {
                          responsive: "lg",
                          items: _vm.metaData[0].records,
                          fields: _vm.fields.indicadores
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "data_mov",
                              fn: function(data) {
                                return [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.formatDate(data.item.data_mov) ===
                                          "Invalid date"
                                          ? ""
                                          : _vm.formatDate(data.item.data_mov)
                                      ) +
                                      "\n          "
                                  )
                                ]
                              }
                            },
                            {
                              key: "meta",
                              fn: function(data) {
                                return [
                                  _c("i", {
                                    staticClass: "fa fa-circle fa-lg",
                                    class: _vm.getMeta(data.item.meta)
                                  })
                                ]
                              }
                            },
                            {
                              key: "vendas",
                              fn: function(data) {
                                return [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm._f("currency")(data.item.vendas, {
                                          symbol: ""
                                        })
                                      ) +
                                      "\n          "
                                  )
                                ]
                              }
                            },
                            {
                              key: "vp",
                              fn: function(data) {
                                return [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm._f("currency")(data.item.vp, {
                                          symbol: ""
                                        })
                                      ) +
                                      "\n          "
                                  )
                                ]
                              }
                            },
                            {
                              key: "va",
                              fn: function(data) {
                                return [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm._f("currency")(data.item.va, {
                                          symbol: ""
                                        })
                                      ) +
                                      "\n          "
                                  )
                                ]
                              }
                            },
                            {
                              key: "descontos",
                              fn: function(data) {
                                return [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm._f("currency")(
                                          data.item.descontos,
                                          { symbol: "" }
                                        )
                                      ) +
                                      "\n          "
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          649389713
                        )
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }